<template>
  <div>

    <el-dialog v-model="showDiagnosisSelection">
      <h6 class="mb-4">Welche Diagnosen wollen Sie hinzufügen?</h6>
      <el-checkbox-group v-if="currentPatient" v-model="selectedDiagnosis">
        <el-checkbox :label="diagnose.id" :key="diagnose.id" v-for="diagnose in currentPatient.diagnosis" class="d-block h-auto">
          <template #default>
            {{$filters.germanDate(diagnose.date)}},
            <span v-if="diagnose.meta.diagnosis_metastasis">{{diagnose.meta.diagnosis_metastasis.title}}, {{diagnose.meta.diagnosis.title}}</span>
            <span v-else-if="diagnose.meta.diagnosis">{{diagnose.meta.diagnosis.code}}, {{diagnose.meta.diagnosis.title}}</span>
          </template>
        </el-checkbox>
      </el-checkbox-group>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDiagnosisSelection = false">Abbrechen</el-button>
        <el-button type="warning" @click="onDiagnoseClick" :disabled="selectedDiagnosis.length === 0">Diagnosen hinzufügen</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="showTherapySelection" width="70%">
      <h6 class="mb-4">Welche Therapien wollen Sie hinzufügen?</h6>
      <el-checkbox-group v-if="currentPatient" v-model="selectedTherapies">
        <el-checkbox :label="therapy.id" :key="therapy.id" v-for="therapy in currentPatient.therapies" class="d-block h-auto">
          <template #default>
            {{$filters.germanDate(therapy.date_start)}},
            <span v-if="therapy.meta.therapy">, {{therapy.meta.therapy.code}}</span>
            <span v-if="therapy.meta.protocol">, {{therapy.meta.protocol.title}}</span>
          </template>
        </el-checkbox>
      </el-checkbox-group>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="showTherapySelection = false">Abbrechen</el-button>
        <el-button type="warning" @click="onTherapyClick" :disabled="selectedTherapies.length === 0">Therapien hinzufügen</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="showFileCardForm" v-if="currentFileCard">
      <h5>{{fullInfo}}</h5>

      <el-row class="mt-4">
        <el-col :span="7">
          <el-date-picker
            class="w-100"
            v-model="currentFileCard.date"
            type="date"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            placeholder="Datum auswählen">
          </el-date-picker>
        </el-col>
        <el-col :span="8" :offset="1">
          <el-select size="medium" class="w-100" v-model="currentFileCard.filecardtype_id" filterable>
            <el-option
              v-for="item in filecardtypes"
              :key="item.id"
              :label="item.code + ', ' + item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7" :offset="1">
          <el-select size="medium" class="w-100" v-model="currentFileCard.username" filterable>
            <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.username"
              :value="item.username">
            </el-option>
          </el-select>
        </el-col>

      </el-row>
      <el-row class="mt-4">
        <el-col :span="24">
          <el-input type="textarea" rows="15" v-model="currentFileCard.content"/>
        </el-col>
      </el-row>

      <template #footer>
        <el-row>

          <el-col :span="10" class="text-left">
            <el-popconfirm
              v-if="currentFileCard.id"
              title="Sie möchten diesen Eintrag löschen?"
              confirmButtonText='Ja, löschen'
              confirmButtonType="danger"
              cancelButtonText='Nein'
              iconColor="red"
              @confirm="onDeleteFileCard">
              <template #reference>
                <el-button type="danger">Löschen</el-button>
              </template>
            </el-popconfirm>&nbsp;
          </el-col>
          <el-col :span="14" class="dialog-footer text-right">
            <el-button @click="showFileCardForm = false">Abbrechen</el-button>
            <el-button type="primary" @click="onSaveFileCard">Eintrag speichern</el-button>
          </el-col>

        </el-row>

      </template>
    </el-dialog>

    <el-card>

    <el-row class="mt-1 mb-4">
      <el-col :span="24">

        <el-table
            ref="filecardtable"
            :data="patientFileCards"
            style="width: 100%"
            highlight-current-row
            @row-click="onFileCardClick"
            stripe>
          <el-table-column
            prop="date"
            label="Datum"
            width="150">
            <template #default="scope">
              <span v-if="scope.row.date">{{ $filters.germanDate(scope.row.date) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="100"
              label="Typ">
            <template #default="scope">
              <span v-if="scope.row.type">{{ scope.row.type.code }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="content"
              label="Inhalt">
          </el-table-column>
          <el-table-column
              width="150">
            <template #default="scope">
              <div class="text-right">
                <el-button size="mini" type="warning" @click="onFileCardClick(scope.row)" icon="el-icon-edit"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-row class="mt-2">
          <el-col :span="6">Neuer Eintrag</el-col>
          <el-col :span="18">
              <el-button type="primary" size="mini" class="ml-2" @click="openDiagnoseSelection">
                Diagnose hinzufügen
              </el-button>
              <el-button type="primary" size="mini" class="ml-2" @click="openTherapySelection">
                Therapie hinzufügen
              </el-button>
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="3">
            <el-date-picker
              size="small"
              class="w-100"
              v-model="newFileCard.date"
              type="date"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
              placeholder="Datum auswählen">
            </el-date-picker>
          </el-col>
          <el-col :span="3">
            <el-select size="small" class="w-100 ml-1" filterable v-model="currentFileCardId">
              <el-option
                v-for="item in filecardtypes"
                :key="item.id"
                :label="item.code + ', ' + item.title"
                :value="item.id"
                @click="onFileCardTypeClick(item)">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="18">
            <el-input type="textarea" class="ml-2" rows="10" v-model="newFileCard.content"/>
            <div class="text-right mt-2">
              <el-button type="primary" @click="onAddFileCard">Eintrag hinzufügen</el-button>
            </div>
          </el-col>
        </el-row>

      </el-col>
    </el-row>
    </el-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
const _ = require('lodash');

export default {
  name: "PatientFileCard",
  props: ['reload'],
  components: {
  },
  data() {
    return {
      currentFileCardId: null,
      patientFileCards: [],
      currentFileCard: null,
      showFileCardForm: false,
      newFileCard: {
        date: moment().format("YYYY-MM-DD"),
        content: ''
      },
      showDiagnosisSelection: false,
      selectedDiagnosis: [],
      showTherapySelection: false,
      selectedTherapies: []
    }
  },
  mounted: async function () {
    await this.getFileCardTypes()
    await this.getUsers()
  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
    ...mapGetters('filecardtypes', ['filecardtypes']),
    ...mapGetters('users', ['users']),
  },
  watch: {
    'reload': async function() {
      if(this.currentPatient) {
        this.newFileCard.patient_id = this.currentPatient.id
        this.loadFileCards()
      }
    }
  },
  methods: {
    ...mapActions('patients', ['getFileCardsOfPatient', 'deleteFileCardOfPatient', 'saveFileCardToPatient']),
    ...mapActions('filecardtypes', ['getFileCardTypes']),
    ...mapActions('users', ['getUsers']),
    async loadFileCards() {
      this.patientFileCards = await this.getFileCardsOfPatient( { patient_id: this.currentPatient.id } )
    },
    onFileCardClick(row) {
      this.currentFileCard = row
      this.showFileCardForm = true
    },
    async onAddFileCard() {
      if(!this.newFileCard.date) {
        this.$message({
          message: "Bitte wählen Sie ein Datum",
          type: 'error'
        });
        return
      }
      if(!this.newFileCard.filecardtype_id) {
        this.$message({
          message: "Bitte wählen Sie einen Karteikarten Typ",
          type: 'error'
        });
        return
      }
      if(!this.newFileCard.content) {
        this.$message({
          message: "Bitte geben Sie den Inhalt der Karteikarte an",
          type: 'error'
        });
        return
      }
      await this.saveFileCardToPatient(this.newFileCard)
      await this.loadFileCards()

      this.newFileCard = {
        date: moment().format("YYYY-MM-DD"),
        content: '',
        patient_id: this.currentPatient.id
      }
    },
    async onSaveFileCard() {
      if(!this.currentFileCard.date) {
        this.$message({
          message: "Bitte wählen Sie ein Datum",
          type: 'error'
        });
        return
      }
      if(!this.currentFileCard.filecardtype_id) {
        this.$message({
          message: "Bitte wählen Sie einen Karteikarten Typ",
          type: 'error'
        });
        return
      }
      if(!this.currentFileCard.content) {
        this.$message({
          message: "Bitte geben Sie den Inhalt der Karteikarte an",
          type: 'error'
        });
        return
      }
      await this.saveFileCardToPatient(this.currentFileCard)
      this.showFileCardForm = false
      await this.loadFileCards()
    },
    async onDeleteFileCard() {
      await this.deleteFileCardOfPatient(this.currentFileCard)
      this.showFileCardForm = false
      this.currentFileCard = null
      await this.loadFileCards()
    },
    openDiagnoseSelection() {
      this.selectedDiagnosis = []
      this.showDiagnosisSelection = true
      if(this.currentPatient && this.currentPatient.diagnosis.length === 1) {
        this.selectedDiagnosis.push(this.currentPatient.diagnosis[0].id)
      }
    },
    openTherapySelection() {
      this.selectedTherapies = []
      this.showTherapySelection = true
      if(this.currentPatient && this.currentPatient.therapies.length === 1) {
        this.selectedTherapies.push(this.currentPatient.therapies[0].id)
      }
    },
    onDiagnoseClick() {

      const exist = _.find(this.filecardtypes, (f) => f.code == 'DB')
      if(exist) {
        this.onFileCardTypeClick(exist)
      }

      let diagnose_str = this.newFileCard.content === '' ? '' : "\n"
      for(const diagnosis_id of this.selectedDiagnosis) {

        const diagnose = _.find(this.currentPatient.diagnosis, (d) => d.id == diagnosis_id)

        if(diagnose.meta.diagnosis_metastasis) {
          diagnose_str += diagnose.meta.diagnosis_metastasis.title + ', '
        }
        if(diagnose.meta.diagnosis) {
          diagnose_str += diagnose.meta.diagnosis.title + ', '
        }
        if(diagnose.tnm) {
          diagnose_str += diagnose.tnm + ', '
        }
        if(diagnose.comment) {
          diagnose_str += diagnose.comment + ', '
        }
        diagnose_str += 'ED ' + this.$filters.germanDate(diagnose.date) + "\n"

      }
      this.newFileCard.content = this.newFileCard.content + diagnose_str
      this.showDiagnosisSelection = false
    },
    onTherapyClick() {

      const exist = _.find(this.filecardtypes, (f) => f.code == 'TV')
      if(exist) {
        this.onFileCardTypeClick(exist)
      }

      let therapy_str = this.newFileCard.content === '' ? '' : "\n"
      for(const therapy_id of this.selectedTherapies) {

        const therapy = _.find(this.currentPatient.therapies, (t) => t.id == therapy_id)

        if(therapy.date_start && therapy.date_end) {
          therapy_str += this.$filters.germanDate(therapy.date_start) + ' - ' + this.$filters.germanDate(therapy.date_end)
        } else {
          if(therapy.date_start) therapy_str += this.$filters.germanDate(therapy.date_start)
        }
        if(therapy.meta.therapy) {
          therapy_str += ', ' + therapy.meta.therapy.code
        }
        if(therapy.meta.protocol) {
          therapy_str += ', ' + therapy.meta.protocol.title
        }
        if(therapy.comment) {
          therapy_str += ', ' + therapy.comment
        }
        if(therapy.meta.therapyunit) {
          therapy_str += ', ' + therapy.meta.therapyunit.code
        }

        therapy_str += "\n"

        // progresses
        for(const progress of this.currentPatient.progress) {
          if(progress.patient_therapy_id == therapy.id) {
            for(const evaluation_index in progress.evaluations) {
//              console.log(evaluation_index)
//              if((evaluation_index + 1) == progress.evaluations.length) {
                therapy_str += "Verlaufskontrolle am " + this.$filters.germanDate(progress.evaluations[evaluation_index].date) + ", " + (progress.evaluations[evaluation_index].result ? progress.evaluations[evaluation_index].result : '')+ "\n"
//              }
            }
          }
        }

      }

      this.newFileCard.content = this.newFileCard.content + therapy_str
      this.showTherapySelection = false

    },
    onFileCardTypeClick(filecard_type) {
      if(filecard_type.autofill) {
        const reversedFileCards = _.reverse(_.cloneDeep(this.patientFileCards))
        for(const filecard of reversedFileCards) {
          if(filecard_type.id == filecard.filecardtype_id && filecard_type.id != this.newFileCard.filecardtype_id) {
            this.newFileCard.content = filecard.content + (this.newFileCard.content !== '' ? '\n' + this.newFileCard.content : '')
          }
        }
      }
      this.newFileCard.filecardtype_id = filecard_type.id
    }
  }
}
</script>
<style scoped>
</style>
